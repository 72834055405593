import type { ParamMatcher } from '@sveltejs/kit';
import type { Category } from '$types/articles.types';
import { LINK_BLOG, LINK_DESTINATIONS, LINK_SERVICES, LOCALIZED_URL_OBJECT } from '$utils/link';

export const match: ParamMatcher = (param) => {
	const destinations = Object.values(LOCALIZED_URL_OBJECT[LINK_DESTINATIONS]);
	const services = Object.values(LOCALIZED_URL_OBJECT[LINK_SERVICES]);
	const blog = Object.values(LOCALIZED_URL_OBJECT[LINK_BLOG]);
	const categories = [...destinations, ...services, ...blog];
	return categories.includes(param);
};
