import { LINK_PRIVACY_POLICY, LOCALIZED_URL_OBJECT } from '$utils/link';
import type { ParamMatcher } from '@sveltejs/kit';
import { localizeParam, matchParam } from 'sveltekit-localize-url';

const localizedParam = localizeParam(
	1,
	'l_privacy_policy',
	LOCALIZED_URL_OBJECT[LINK_PRIVACY_POLICY]
);
export const match: ParamMatcher = (param) => matchParam(param, localizedParam);
