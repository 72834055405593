import { LOCALIZED_URL_OBJECT } from '$utils/link';
import type { ParamMatcher } from '@sveltejs/kit';

// const localizedParam = localizeParam(1, 'l_about', LOCALIZED_URL_OBJECT[LINK_ABOUT]);
export const match: ParamMatcher = (param) => {
	return (
		/^from-palma-airport-to(.+)$/.test(param) || // English pattern
		/^desde-aeropuerto-palma-a(.+)$/.test(param) || // Spanish pattern
		/^von-flughafen-palma-nach(.+)$/.test(param) // German pattern
	);
};
